import axios from 'axios'
import { getToken } from '@/utils/token'
import { Message } from 'element-ui';
import router from 'vue-router'
axios.defaults.baseURL = `https://test.apis.yihuichuang.com/`;
// 添加请求拦截器
// 在发送请求之前做些什么
axios.interceptors.request.use((config)=>{
    // config.headers['token'] = getToken();
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.headers['token'] = getToken();
    return config;
},error=>Promise.error(error))
// 添加响应拦截器
axios.interceptors.response.use((response)=>{
    // 对响应数据做点什么
    console.log(response.data)
    let {code,info} = response.data;
    console.log(code,info)
    if(code == -1000){
        router.push('/login')
    }
    // if(code != 20000){
    //     Message({
    //         message: msg || '网络错误',
    //         type: 'warning',
    //         duration:2000
    //       });
    // }
    // return code === 200 ? Promise.resolve(response.data) : Promise.reject(response.data)
    return response
}, err=>{
    // 对响应错误做点什么
    // Message({
    //     message: '服务器不给力！！！',
    //     type: 'error',
    //     duration:6000 * 10
    //   });
    const {response} = error
    if(response){
      return Promise.reject(err);
    }
})

export default axios