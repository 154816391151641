import request from '@/utils/request'  
import qs from 'qs'
export function login(data){   //登录
    return request ({
        method:'post',
        url:`Admin/login`,
        data:qs.stringify(data)
    })
}
export function logList(data){   //管理员日志
    return request ({
        method:'post',
        url:`Admin/logList`,
        data:qs.stringify(data)
    })
}
export function logout(){   //退出
    return request ({
        method:'post',
        url:`Admin/logout`
    })
}
export function logInfo(){   //管理员信息
    return request ({
        method:'post',
        url:`Admin/info`
    })
}
export function resetPwd(){   //管理员修改密码
    return request ({
        method:'post',
        url:`Admin/resetPwd`
    })
}
export function homeData(data){//首页总数据
    return request({
        method:'post',
        url:`Admin/home`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function homeUser(data){  //总用户数折线图
    return request({
        method:'post',
        url:`Admin/homeUser`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

export function homeZz(data){ //总站长数折线图
    return request({
        method:'post',
        url:`Admin/homeZz`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
//用户中心
export function userList(data){//用户列表
    return request({
        method:'post',
        url:`Admin/userCenter`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

export function userCenterJK(data){
    return request({//荐客列表
        method:'post',
        url:`Admin/userCenterJK`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function userCenterAddr(data){
    return request({//用户地址
        method:'post',
        url:`Admin/userCenterAddr`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

//站长中心
export function stationList(data){ //站长列表
    return request({
        method:'post',
        url:`Admin/zzCenter`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function stationEdit(data){ //编辑站长
    return request({//用户地址
        method:'post',
        url:`Admin/zzCenterEdit`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function stationAdd(data){ //添加站长
    return request({//用户地址
        method:'post',
        url:`Admin/zzCenterAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function stationUser(data){ //站长推广
    return request({
        method:'post',
        url:`Admin/zzCenterUser`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function stationUserBind(data){ //添加下级
    return request({//用户地址
        method:'post',
        url:`Admin/zzCenterUserBind`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
//订单中心
export function orderList(data){ //订单列表
    return request({
        method:'post',
        url:`Admin/orderManage`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function orderManageExpress(data){ //快速发货
    return request({
        method:'post',
        url:`Admin/orderManageExpress1`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function orderManagePrint(data){ //文件列表
    return request({
        method:'post',
        url:`Admin/orderManagePrint`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function orderManageRemark(data){ //备注列表
    return request({
        method:'post',
        url:`Admin/orderManageRemark`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function orderManageRemarkAdd(data){ //备注添加
    return request({//
        method:'post',
        url:`Admin/orderManageRemarkAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function orderManageRefundAsk(data){ //退款申请
    return request({
        method:'post',
        url:`Admin/orderManageRefundAsk`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function orderDownloadFile(data){ //退款申请
    return request({
        method:'post',
        url:`Admin/orderManageDownZip`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function orderManageSetState(data){ //设置订单状态
    return request({//
        method:'post',
        url:`Admin/orderManageSetState`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function orderManageDetail(data){ //设置订单状态
    return request({//订单详情
        method:'post',
        url:`Admin/orderManageDetail`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

//财务列表
export function financeList(data){ //备注添加
    return request({//
        method:'post',
        url:`Admin/finance`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function financeRefund(data){ //退款列表
    return request({
        method:'post',
        url:`Admin/financeRefund`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function financeRefundExamine(data){ //退款审批
    return request({//
        method:'post',
        url:`Admin/financeRefundExamine`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
//体现列表
export function incomeList(data){ //提现列表
    return request({//
        method:'post',
        url:`Admin/income`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function incomeExamine(data){ //体现管理审批
    return request({
        method:'post',
        url:`Admin/incomeExamine`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
//公告列表
export function noticeList(data){ //公告列表
    return request({//
        method:'post',
        url:`Admin/message`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function noticeAdd(data){ //公告添加
    return request({
        method:'post',
        url:`Admin/messageAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function noticeDel(data){ //公告删除
    return request({//
        method:'post',
        url:`Admin/messageDel`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function noticeEdit(data){ //公告编辑
    return request({
        method:'post',
        url:`Admin/messageEdit`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

//轮播图管理
/* 轮播列表*/
export function bannerList(data){ //轮播图列表
    return request({//
        method:'post',
        url:`Admin/slider`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function bannerAdd(data){ //轮播图添加
    return request({
        method:'post',
        url:`Admin/sliderAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function bannerDel(data){ //轮播图删除
    return request({//
        method:'post',
        url:`Admin/sliderDel`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function bannerEdit(data){ //轮播图编辑
    return request({
        method:'post',
        url:`Admin/sliderEdit`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
//文件接口
export function fileFind(data){ //轮播图列表
    return request({//
        method:'post',
        url:`Admin/fileFind`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function fileSave(data){ //轮播图添加
    return request({
        method:'post',
        url:`Admin/fileSave`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

//文章
export function article(data){ //文章列表
    return request({//
        method:'post',
        url:`Admin/article`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function articleGet(data){ //文章详情
    return request({
        method:'post',
        url:`Admin/articleGet`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function articleAdd(data){ //文章添加
    return request({//
        method:'post',
        url:`Admin/articleAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function articleDel(data){ //文章删除
    return request({
        method:'post',
        url:`Admin/articleDel`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function articleEdit(data){ //文章编辑
    return request({//
        method:'post',
        url:`Admin/articleEdit`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function articleCategory(data){ //文章分类列表
    return request({
        method:'post',
        url:`Admin/articleCategory`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

export function articleCategoryAdd(data){ //文章分类添加
    return request({
        method:'post',
        url:`Admin/articleCategoryAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function articleCategoryDel(data){ //文章分类删除
    return request({//
        method:'post',
        url:`Admin/articleCategoryDel`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function articleCategoryEdit(data){ //文章分类编辑
    return request({
        method:'post',
        url:`Admin/articleCategoryEdit`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

//用户反馈列表
export function feedBack(data){ //用户反馈列表
    return request({
        method:'post',
        url:`Admin/feedback`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}

/* 问答管理列表*/
export function question(data){ //添加问答
    return request({
        method:'post',
        url:`Admin/qa`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function questionAdd(data){ //添加问答
    return request({
        method:'post',
        url:`Admin/qaAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function questionDel(data){ //删除问答
    return request({
        method:'post',
        url:`Admin/qaDel`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function questionEdit(data){ //编辑问答
    return request({
        method:'post',
        url:`Admin/qaEdit`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function questionCategory(data){ //问答分类列表
    return request({
        method:'post',
        url:`Admin/qaCategory`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function questionCategoryAdd(data){ //添加问答分类
    return request({
        method:'post',
        url:`Admin/qaCategoryAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function questionCategoryEdit(data){ //编辑问答分类
    return request({
        method:'post',
        url:`Admin/qaCategoryEdit`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function questionCategoryDel(data){ //删除问答分类
    return request({
        method:'post',
        url:`Admin/qaCategoryDel`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
//优惠券管理
export function coupon(data){ //优惠券列表
    return request({
        method:'post',
        url:`Admin/coupon`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function couponAdd(data){ //优惠券添加
    return request({
        method:'post',
        url:`Admin/couponAdd`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function couponDel(data){ //优惠券删除
    return request({
        method:'post',
        url:`Admin/couponDel`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}
export function couponEdit(data){ //优惠券添加
    return request({
        method:'post',
        url:`Admin/couponEdit`,
        data:qs.stringify(Object.assign(data,{admin_token:localStorage.getItem('yhc_admin_token')}))
    })
}